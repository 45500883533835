<template>
    <div style="overflow-x: auto">

        <!-- Are You Sure Delete -->
        <b-modal no-close-on-backdrop scrollable ok-title="Submit" title="Delete Organization" class="modal-dark"
                 v-model="areYouSureDelete">
            <p>If you would like to permanently remove this organization please type the name Below.</p>
            <br>
            <p>Enter "{{ currentOrganization.orgName }}":</p>
            <b-form-group description="case sensitive">
                <b-form-input v-model="organizationNameVerification" id="basicName3" type="text"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="areYouSureDelete=false">Cancel</b-button>
                <b-button size="sm" :disabled="organizationNameVerification !== currentOrganization.orgName"
                          variant="danger" @click="deleteOrganization">Submit
                </b-button>
            </template>
        </b-modal>

        <!--More Info Modal-->
        <b-modal scrollable title="More Info" class="modal-dark" v-model="moreInfo">
            <router-link :to="{ name: 'Organization Details', params: {id: currentOrganization.organizationId}}">
                <b-button block variant="primary">"{{ currentOrganization.orgName }}" Organization Page</b-button>
            </router-link>
            <br>
            <b-button @click="areYouSureDelete = true" block variant="danger">Delete Organization</b-button>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="moreInfo = false">Cancel</b-button>
            </template>
        </b-modal>

        <!--Create Organization-->
        <b-modal no-close-on-backdrop scrollable title="Create Organization" class="modal-dark" v-model="createOrg">
            <b-row>

                <!--Organization Name-->
                <b-col sm="7">
                    <b-form-group>
                        <label>Organization Name:</label>
                        <InlineSearch :form-control="filledOut(newOrg.orgName)"
                                      v-model="newOrg.orgName" href-info="Organization Details"
                                      data-type="orgName" :arrow="false" id-name="organizationId"
                                      :data="this.organizations" :upper-case="true"/>
                    </b-form-group>
                </b-col>

                <!--Status-->
                <b-col sm="5">
                    <b-form-group>
                        <label>Status:</label>
                        <b-form-select
                            :class=" 'form-control is-' + (filledOut(newOrg.status) ? 'valid':'invalid')"
                            :options="['NO STATUS','ACTIVE', 'INACTIVE']"
                            v-model="newOrg.status"/>
                    </b-form-group>
                </b-col>

                <!--Description-->
                <b-col sm="12">
                    <b-form-group>
                        <label>Description:</label>
                        <textarea class="form-control rounded-0" rows="7" v-model="newOrg.description"
                                  type="text"></textarea>

                    </b-form-group>
                </b-col>

                <!--Notes-->
                <b-col sm="12">
                    <b-form-group>
                        <label>Notes</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(newOrg.notes, {text: ''})"/>
                        <b-input-group v-for="(e,i) in newOrg.notes" :key="i" v-if="refresh">
                            <b-form-input v-model="e.text"/>
                            <b-input-group-append v-if="e.length > 1">
                                <b-button variant="dark" @click="splice(i, e)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>

            </b-row>
            <span slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="createOrg = false">Cancel</b-button> &nbsp;
                <b-button size="sm" variant="primary" :disabled="disableOrgSubmit()"
                          @click="postOrganization()">Submit</b-button>
            </span>
        </b-modal>

        <!--Organization Cards-->
        <b-card style="width: 100%; height: 100%; margin: 0; padding: 0">
            <div slot="header">
                <span class="d-flex w-100 justify-content-between">
                    <h5 style="margin-bottom: 0"><b>Organization Overview</b></h5>
                    <span style="display: flex; align-items: baseline">
                      <fa-icon :icon="['fas', 'square-plus']" style="cursor: pointer;" @click="openCreate"/>
                    </span>
                </span>
            </div>
            <v-client-table @row-clicked="rowClicked" ref="dTable" style="width: 100%" :columns="columns"
                            :data="organizations" :options="tOptions" :theme="profiles.theme" class="dataTable">
                <div slot="info" slot-scope="props">
                    <router-link :to="{ name: 'Organization Details', params: {id: props.row.organizationId}}">
                        <b-button style="float: right; color: #20a8d8; background-color: #2a2a2a; padding: 4px 15px;" size="sm" variant="dark">
                            <fa-icon :icon="['fas', 'info']" size="lg"/>
                        </b-button>
                    </router-link>
                </div>
                <div slot="description" slot-scope="props">
                    <span v-if="props.row.description && props.row.description.length > 193">
                        {{ props.row.description.substring(0, 193) }}...
                    </span>
                    <span v-else>{{ props.row.description }}</span>
                </div>
                <div slot="h__info">
                    <span style="float: right; margin-bottom: 0">Info/Edit</span>
                </div>
            </v-client-table>
        </b-card>
    </div>
</template>

<script>
import risc from '@/services/risc.js'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'
import InlineSearch from "../InlineSearch";

Vue.use(ClientTable)

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyASyYRBZmULmrmw_P9kgr7_266OhFNinPA'
        // To use the Google Maps JavaScript API, you must register your app project on the Google API Console and get a Google API key which you can add to your app
        // v: 'OPTIONAL VERSION NUMBER',
        // libraries: 'places', //// If you need to use place input
    }
});

export default {

    components: {
        InlineSearch,
    },
    data: () => {
        return {
            search: {
                active: false,
                text: "",
            },
            columns: ['orgName', 'status', 'description', 'info'],
            tOptions: {
                sortable: ['orgName', 'status', 'description'],
                filterable: ['orgName', 'status', 'description', 'info'],
                orderBy: {column: 'orgName', ascending: true},
                headings: {
                    name: 'Organization Name',
                    status: 'Status',
                    description: 'Description',
                    info: 'Info/Edit',
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            tooltipTitle: "",
            tooltipRender: true,
            target: '',
            organizations: [],
            fields: [
                {field: 'name', name: 'Name'},
                {field: 'FF', name: 'Members'},
                {field: 'associates', name: 'Associates'},
                {field: 'male', name: 'Male'},
                {field: 'female', name: 'Female'},
                {
                    field: 'addresses',
                    name: 'Addresses',
                    type: 'Active',
                    required: false,
                    placeholder: "",
                    format: {address: '', address2: '', city: '', state: '', zip: '', notes: '', active: true}
                },
                {field: 'status', name: 'Status', type: 'String', required: false, placeholder: ""},
                {field: 'notes', name: 'Notes', type: 'Array', required: false, placeholder: ""},
            ],
            currentOrganization: {},
            newOrg: {},
            createTemplate: {
                organizationId: 0,
                orgName: '',
                description: '',
                status: '',
                notes: [{"text": ""}]
            },
            address: {
                states: [
                    'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
                    'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
                    'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
                    'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
                    'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
                ],
            },
            refresh: true,
            organizationNameVerification: '',
            editOrg: {orgName: '', description: '', status: ''},
            createOrg: false,
            moreInfo: false,
            areYouSureDelete: false,
            center: {lat: 42.2711, lng: -89.0940},
            markers: [
                {
                    position: {lat: 42.2711, lng: -89.20},
                    label: 'S',
                    draggable: false,
                    title: 'Stanford',
                    www: 'https://www.stanford.edu/'
                }, {
                    position: {lat: 42.2711, lng: -89.00},
                    label: 'T',
                    draggable: false,
                    title: 'Tesla',
                    www: 'https://www.tesla.com/'
                }, {
                    position: {lat: 42.2800, lng: -89.09},
                    label: 'A',
                    draggable: false,
                    title: 'Apple',
                    www: 'https://www.apple.com/'
                }, {
                    position: {lat: 42.2651, lng: -89.09},
                    label: 'F',
                    draggable: false,
                    title: 'Facebook',
                    www: 'https://www.facebook.com/'
                }
            ],
            infoContent: '',
            mapStyle: {
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [{"color": "#212121"}]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [{"visibility": "off"}]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [{"color": "#757575"}]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [{"color": "#212121"}]},
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [{"color": "#757575"}]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "labels.text.fill",
                        "stylers": [{"color": "#9e9e9e"}]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "stylers": [{"visibility": "off"}]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.fill",
                        "stylers": [{"color": "#bdbdbd"}]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [{"color": "#757575"}]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [{"color": "#181818"}]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [{"color": "#616161"}]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.stroke",
                        "stylers": [{"color": "#1b1b1b"}]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [{"color": "#2c2c2c"}]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [{"color": "#8a8a8a"}]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [{"color": "#373737"}]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [{"color": "#3c3c3c"}]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [{"color": "#20a8d8"}]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry",
                        "stylers": [{"color": "#4e4e4e"}]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [{"color": "#616161"}]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "labels.text.fill",
                        "stylers": [{"color": "#757575"}]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [{"color": "#000000"}]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [{"color": "#3d3d3d"}]
                    }
                ]
            },
            infoLink: '',
            infoWindowPos: {
                lat: 0,
                lng: 0
            },
            infoWinOpen: false,
            currentMidx: null,
            // optional: offset info window, so it visually sits nicely on top of our marker
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            profiles: {
                data: [],
                select: {
                    selected: [],
                    allMarked: false,
                },
                columns: ['name', 'status', 'description'],
                tOptions: {
                    sortable: ['name', 'DOB', 'knownAlias'],
                    filterable: ['name', 'DOB', 'knownAlias'],
                    orderBy: {column: 'name', ascending: true},
                    headings: {},
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                theme: 'bootstrap4',
                template: 'default',
                createCaseModal: false
            },
        }
    },

    created() {
        this.getOrganization()
    },

    methods: {
        active(e) {
            e.active = !e.active;
            this.refresh = false;
            this.refresh = true;
        },
        arrayify(start, end, addToEnd) {
            let result = [];
            for (let i = start; i < end + 1; i++) {
                if (addToEnd) {
                    if (i > 9) {
                        result.push(i)
                    } else if (i >= 0) {
                        result.push("0" + i)
                    }
                } else {
                    if (i > 9) {
                        result.unshift(i)
                    } else if (i >= 0) {
                        result.unshift("0" + i)
                    }
                }
            }
            return (result);
        },
        /*startSearch() {
            if (!this.search.active) {
                this.search.active = true;
                this.search.text = "";
            } else {
                this.search.active = false;
                this.search.text = "";
            }
        },*/
        openCreate() {
            this.profiles.select.selected = [];
            risc.getProfiles().then(response => {
                this.profiles.data = response.data;
                response.data.forEach((profile, index) => {
                    if (index === null) {
                        let temp = "";
                        profile.alias.forEach((e, i) => {
                            temp += e.knownAlias + (profile.alias.length === i + 1 ? "" : ", ")
                        }).then(() => {
                            this.profiles.data[index] = {
                                name: this.nameify(profile),
                                DOB: this.dateify('', '', '',),
                                knownAlias: temp,
                                profileId: profile.profileId
                            }
                        })
                    }
                })
                this.newOrg = JSON.parse(JSON.stringify(this.createTemplate));
                this.createOrg = true;
            })
        },

        filledOut(i) {
            return !(i === null || i === undefined || i === '');

        },

        disableOrgSubmit() {
            return !this.filledOut(this.newOrg.orgName) ||
                !this.filledOut(this.newOrg.status);

        },
        rowClicked(item) {
            //ctrl click to make tooltip appear with id
            this.tooltipRender = false;
            this.tooltipTitle = item._id;
            this.target = item._id;
            //restart tooltip so it can map to its new position
            Vue.nextTick().then(() => {
                this.tooltipRender = true;
                Vue.nextTick().then(() => {
                    this.$refs.tooltip.$emit('open')
                    setTimeout(function () {
                        //make the tooltip disappear automatically
                        this.$refs.tooltip.$emit('close');
                    }.bind(this), 4000)
                });
            });
        },
        random() {
            let min = 1;
            let max = 200;
            return (Math.floor(Math.random() * (+max - +min)) + +min);
        },
        splice(index, field) {
            field.splice(index, 1);
            this.refresh = false;
            this.refresh = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
            this.refresh = false;
            this.refresh = true;

        },
        getOrganization() {
            risc.getOrganizations().then(response => {
                this.organizations = response.data;
            })
        },
        postOrganization() {
            this.newOrg.profiles = this.profiles.select.selected
            risc.postOrganizations(this.newOrg).then(response => {
                this.getOrganization()
                this.createOrg = false;
            })
        },
        deleteOrganization() {
            this.areYouSureDelete = false;
            risc.deleteOrganizations(this.currentOrganization).then(() => {
                this.getOrganization()
            })
        },
        /*openInfo(device) {
            this.currentOrganization = {};
            this.currentOrganization = device;
            this.moreInfo = true
        },*/
        nameify(profile) {
            if (profile.firstName && profile.lastName && profile.middleName) {
                return (profile.lastName + ', ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
            } else if (profile.firstName && profile.lastName) {
                return (profile.lastName + ', ' + profile.firstName)
            } else if (profile.firstName && profile.middleName) {
                return ('Unknown, ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
            } else if (profile.lastName && profile.middleName) {
                return (profile.lastName + ', Unknown ' + profile.middleName.slice(0, 1))
            } else if (profile.firstName) {
                return ('Unknown, ' + profile.firstName)
            } else if (profile.lastName) {
                return (profile.lastName + ', Unknown ')
            } else if (profile.middleName) {
                return ('Unknown, Unknown ' + profile.middleName.slice(0, 1))
            }
        },
        dateify(month, day, year) {
            let result = "";
            result += (!month ? 'XX' : (month < 10 ? "0" + month : month)) + '/';
            result += (!day ? 'XX' : (day < 10 ? "0" + day : day)) + '/';
            result += (!year ? 'XXXX' : year);
            return result;
        },
        unmarkAndMarkAll(selected, allMarked) {
            if (allMarked === false) {
                let temp = [];
                if (this.$refs.dTable.allFilteredData) {
                    this.$refs.dTable.allFilteredData.forEach(function (item) {
                        temp.push(item.id);
                    });
                }
                return (temp)
            } else {
                return ([])
            }
        },
        findAddress(arr) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].active) {
                    return (arr[i].address)
                } else if (i === arr.length - 1) {
                    return (arr[i].address)
                }
            }
        }
    }
}

/*Makes B-Form-Input All Caps*/
Vue.directive('uppercase', {
    update(el) {
        el.value = el.value.toUpperCase()
    },
})

</script>

<style scoped>
.noSpace {
    margin: 0 !important;
    padding: 0 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.fit {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    width: 100%;
}
</style>