<template>
    <span>

        <!--Modal-->
        <b-modal class="modal-dark" title="Redirect Notice" :v-model="focusInput">
          suh
        </b-modal>

        <!--Form Input-->
        <div style="position: relative" @mouseleave="focusInput = false" @mouseenter="focusInput = true"
             @input="focusInput = true">
            <b-input-group>
                <b-form-input v-if="upperCase"
                              autocomplete="off"
                              v-model="input" @input="search()"
                              :class="show && results.length > 0 ? 'mergeInputs ' : ''"
                              :size="size ? size : 'md'"
                              :style="formControl !== undefined ? (formControl ? ';border-color: #4dbd74' : ';border-color: #f86c6b') : ''"
                              style="border-right: none; outline-width: 0"
                              @keyup.down="drop()"/>
                <div class="input-group-append ">
                    <span class="input-group-text color-inherit"
                            style="background-color: #333333; border-left: none; outline-width: 0; cursor: pointer;"
                            :style="formControl !== undefined ? (formControl ? ';border-color: #4dbd74' : ';border-color: #f86c6b') : ''"
                            :class="show && results.length > 0 ? 'mergeInputs' : ''">
                        <fa-icon v-if="show && results.length > 0" :icon="['fas', 'caret-up']"/>
                        <fa-icon v-else-if="arrow" :icon="['fas', 'caret-down']"/>
                    </span>
                </div>
            </b-input-group>
            <b-form-select class="input"
                           @mouseleave="focusSelect = false"
                           @mouseenter="focusSelect = true"
                           v-show="show"
                           :style="formControl !== undefined ? (formControl ? ';border-color: #23282c #4dbd74 #4dbd74 #4dbd74' : ';border-color: #23282c #4dbd74 #4dbd74 #4dbd74') : ''"
                           style="z-index: 1; position: absolute; border-top-left-radius: 0; border-top-right-radius: 0; background-color: #333333"
                           :size="size ? size : 'md'"
                           ref="select" :select-size="results.length > 5 ? 5 : (results.length < 2 ? 2 : results.length)"
                           v-model="output"
                           @keyup.enter.native="go = true"
                           v-if="results && Array.isArray(results) && results.length > 0">
                <b-form-select-option v-for="e in results" :value="e[idName]" :key="e[idName] + 'succ'" @click="go = true" style="font-family: FontAwesome, sans-serif;">
                    {{resultFormat(e)}}
                </b-form-select-option>
            </b-form-select>
        </div>

    </span>
</template>

<script>

    export default {
        name: "InlineSearch",

        props: {
            formControl:{
                type: Boolean,
                default: undefined
            },
            hrefInfo: {
                type: String,
                required: true
            },
            idName: {
                type: String,
                required: true
            },
            data: {
                type: Array,
                default: []
            },
            dataType: {
                type: String,
                default: ''
            },
            resultFormat: {
                type: Function,
                default: function (data) {
                    return data[this.dataType]
                }
            },
            size: {
                type: String
            },
            arrow: {
                type: Boolean,
                default: true
            },
            upperCase: {
                type: Boolean,
                default: false
            },

        },

        data: () => {
            return {
                go: false,
                focusInput: false,
                focusSelect: false,
                output: '',
                results: [],
                show: false,
                input: '',
            }
        },

        created() {
        },

        updated() {
        },

        methods: {
            search() {
                if(this.input && this.input.length > 0){
                    this.results = this.data.filter(e => {
                        if (e[this.dataType]) {
                            return e[this.dataType].toUpperCase().includes(this.input.toUpperCase())
                        }
                        return false;
                    }).sort((a, b) => this.sortify(a, b))
                }else{
                    this.results = []
                }
            },
            sortify(a, b) {
                if (a[this.dataType].toLowerCase().startsWith(this.input.toLowerCase()) && !b[this.dataType].toLowerCase().startsWith(this.input.toLowerCase())) {
                    return -1;
                } else if (!a[this.dataType].toLowerCase().startsWith(this.input.toLowerCase()) && b[this.dataType].toLowerCase().startsWith(this.input.toLowerCase())) {
                    return 1;
                }
                return a[this.dataType].localeCompare(b[this.dataType])
            },
            drop() {
                if (this.results[0]) {
                    this.$refs.select.focus()
                    this.output = this.results[0][this.idName]
                    this.$refs.select.$forceUpdate()
                }
                this.$bvModal.show('redirectModal')
            }
        },

        watch: {
            input() {
                this.input = this.input.toUpperCase()
                this.$emit('input', this.input)
            },
            focusInput() {
                this.show = this.focusSelect || this.focusInput;

            },
            focusSelect() {
                this.show = this.focusSelect || this.focusInput;

            },
            go() {
                if(this.go){
                    let routeData = this.$router.resolve({ name: this.hrefInfo, params: { id: this.output }})
                    window.open(routeData.href, '_blank')
                    this.go = false
                }
            }
        },

    }

</script>

<style scoped>
    .mergeInputs {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: 0
    }

    :focus {
        box-shadow: none;
        border-color: rgb(35, 40, 44);
    }
</style>