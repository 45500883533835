import { render, staticRenderFns } from "./OrganizationsOverview.vue?vue&type=template&id=516838a9&scoped=true"
import script from "./OrganizationsOverview.vue?vue&type=script&lang=js"
export * from "./OrganizationsOverview.vue?vue&type=script&lang=js"
import style0 from "./OrganizationsOverview.vue?vue&type=style&index=0&id=516838a9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "516838a9",
  null
  
)

export default component.exports